import React from "react"

import { FormInput } from "../../Form/Input/FormInput"
import { StyledForm } from "../../Styled/Form"
import { StyledButton } from "../../Styled/Button"
import { P } from "../../Styled/Text"
import { withCartDiscount } from "./withCartDiscount"

export const CartDiscount = withCartDiscount(
  ({ activeBox, applied, data, errors, field, handleChange, handleSubmit, locales, loading }): JSX.Element =>
    activeBox ? (
      <StyledForm onSubmit={handleSubmit}>
        <FormInput
          name={`discount`}
          errors={errors}
          onChange={handleChange}
          placeholder={locales?.additionalDiscountAdd}
          reference={field}
          required
          value={data?.discount}
          withSpacing={`xs`}
        />
        <StyledButton
          type={`submit`}
          colour={`orange`}
          disabled={loading}
          full
          size={`secondary`}
          title={applied ? locales?.additionalDiscountApplied : locales?.additionalDiscountApply}
          withSpacing={"xxs"}
        >
          {applied ? locales?.additionalDiscountApplied : locales?.additionalDiscountApply}
        </StyledButton>
      </StyledForm>
    ) : (
      <P align={`left`} withSpacing={`xs`}>
        {"Promo Codes and Gift Cards cannot be used to purchase a Gift Card"}
      </P>
    )
)
