import React from "react"
import { Link as GatsbyLink } from "gatsby"
import Measure from "react-measure"

import { withCart } from "./withCart"
import { CartDiscount } from "./Discount/CartDiscount"
import { CartNotes } from "./Notes/CartNotes"
import { CartList } from "./List/CartList"
import { CartTotal } from "./Total/CartTotal"
import { CartPayments } from "./Payments/CartPayments"
import { CartRecommendations } from "./Recommendations/CartRecommendations"
import { StyledContainer } from "../Styled/Container"
import { StyledColumn } from "../Styled/Column"
import { StyledRow } from "../Styled/Row"
import { H4 } from "../Styled/Text"
import { Page, Link, Button } from "./styledCart"

export const Cart = withCart(
  ({
    bounds,
    breadcrumb,
    count,
    empty,
    global,
    handleResize,
    link,
    page,
    canSaveNotes,
    handleUpdateNoteStatus,
    showNotesError,
    handleNotesError,
  }): JSX.Element => (
    <Page>
      <StyledContainer width={`xl`}>
        {breadcrumb?.url && (
          <StyledRow justify={`start`} withSpacing={`md`}>
            <Link colour={`grey-darker`} as={GatsbyLink} title={breadcrumb?.title} to={breadcrumb?.url}>
              {breadcrumb?.title}
            </Link>
          </StyledRow>
        )}

        {count > 0 ? (
          <StyledRow gutter={`lg`} withWrap withSpacing={`xl`}>
            <StyledColumn gutter={`lg`} screen={`<sm`}>
              <H4 withSpacing={"default"}>{page?.title}</H4>
              <CartList drawer />
            </StyledColumn>
            <StyledColumn gutter={`lg`} screen={`md>`} width={`2/3`}>
              <H4 withSpacing={"default"}>{page?.title}</H4>
              <CartList bounds={bounds} />
            </StyledColumn>
            <StyledColumn gutter={`lg`} width={`md:1/3`}>
              <H4 withSpacing={"default"}>{page?.additionalTotal}</H4>
              <Measure bounds onResize={handleResize}>
                {({ measureRef }) => (
                  <div ref={measureRef}>
                    <CartDiscount page={page} />
                    <CartNotes
                      page={page}
                      canSaveNotes={canSaveNotes}
                      showNotesError={showNotesError}
                      handleNotesError={handleNotesError}
                      handleUpdateNoteStatus={handleUpdateNoteStatus}
                      setHeight={`full`}
                      show={`visible`}
                    />
                    <CartTotal page={page} disabled={canSaveNotes} handleDisabledClick={handleNotesError} />
                    <CartPayments />
                  </div>
                )}
              </Measure>
            </StyledColumn>
          </StyledRow>
        ) : empty ? (
          <StyledRow withSpacing={`xxl`}>
            <StyledColumn items={`center`} vertical>
              <H4 withSpacing={"default"}>{page?.additionalEmpty}</H4>
              {link?.url && (
                <Button as={GatsbyLink} colour={`purple`} size={`primary`} title={link?.title} to={link?.url}>
                  {link?.title}
                </Button>
              )}
            </StyledColumn>
          </StyledRow>
        ) : null}
      </StyledContainer>

      {page?.recommendations && <CartRecommendations global={global} page={page} />}
    </Page>
  )
)
