import React from "react"

import { withCartRecommendations } from "./withCartRecommendations"
import { FeaturedProductsItem } from "../../Sections/FeaturedProducts/Item/FeaturedProductsItem"
import { FeaturedProductsSlides } from "../../Sections/FeaturedProducts/Slides/FeaturedProductsSlides"
import { Section } from "../../Sections/FeaturedProducts/styledFeaturedProducts"

export const CartRecommendations = withCartRecommendations(
  ({ autoplay, bounds, colour, id, items, locales }): JSX.Element => (
    <Section>
      {items?.length > 1 ? (
        <FeaturedProductsSlides
          autoplay={autoplay}
          colour={colour}
          id={id}
          items={items}
          locales={locales}
          pretitle={locales?.additionalRelated}
          top={bounds?.height}
        />
      ) : (
        <>
          {items?.map((item: any) => (
            <FeaturedProductsItem key={item?.id} item={item} pretitle={locales?.additionalRelated} top={bounds?.height} />
          ))}
        </>
      )}
    </Section>
  )
)
