import React, { memo, useMemo } from "react"

import { useApp } from "../../../hooks/useApp"
import { useCheckoutContext } from "../../../hooks/useCheckout"
import { useShopify } from "../../../hooks/useShopify"

export const withCartRecommendations = Component =>
  memo(({ name = "CartRecommendations", id = `cart`, global, page }: any) => {
    const { headerBounds } = useApp()
    const { checkout } = useCheckoutContext()
    const { useProductRecommendations, usePopularProducts } = useShopify()

    const recommendation = checkout?.lineItems?.[checkout?.lineItems?.length - 1]?.variant?.product
    const recommendations = useProductRecommendations({ id: recommendation ? recommendation?.id : null, firstImages: 5, firstVariants: 1 })
    const popular = usePopularProducts({ first: 3, firstImages: 5, firstVariants: 1 })
    const items = recommendations?.length ? recommendations : popular
    const autoplay = page?.recommendationsAutoplay && page?.recommendationsAutoplaySeconds ? page.recommendationsAutoplaySeconds * 1000 : null
    const colour = page?.recommendationsArrows?.toLowerCase()?.replace(`light`, `white`)

    const locales = useMemo(
      () => ({
        ...global,
        ...page,
      }),
      [global, page]
    )

    Component.displayName = name
    return useMemo(
      () =>
        items?.length > 0 ? <Component autoplay={autoplay} bounds={headerBounds} colour={colour} id={id} items={items} locales={locales} /> : null,
      [autoplay, colour, headerBounds, id, items, locales]
    )
  })
