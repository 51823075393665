import React from "react"

import { withCartPayments } from "./withCartPayments"
import { Icon } from "../../Icon/Icon"
import { StyledRow } from "../../Styled/Row"
import { Payment } from "./styledCartPayments"

export const CartPayments = withCartPayments(
  ({ items }): JSX.Element => (
    <StyledRow items={`center`}>
      {items?.map(({ id, title }) => (
        <Payment key={id}>
          <Icon name={`payments-${id}`} title={title} />
        </Payment>
      ))}
    </StyledRow>
  )
)
