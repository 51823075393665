import React, { memo, useCallback, useMemo, useRef, useState, useEffect } from "react"

import { useCheckout } from "../../../hooks/useCheckout"

const INITIAL_STATE = {
  discount: "",
}

export const withCartDiscount = Component =>
  memo(({ name = "CartDiscount", page }: any) => {
    const { applyDiscountCode, applyGiftCardCode } = useCheckout()
    const [data, setData] = useState(INITIAL_STATE)
    const [errors, setErrors] = useState([])
    const [loading, setLoading] = useState(false)
    const [applied, setApplied] = useState(false)
    const [activeBox, setActiveBox] = useState(true)

    const { checkout, count } = useCheckout()

    const field = useRef()

    const handleSubmit = useCallback(
      async event => {
        event.preventDefault()

        setLoading(true)

        const discount = await applyDiscountCode(data?.discount)

        if (!(discount?.checkoutUserErrors?.length > 0)) {
          setApplied(true)
          setData(INITIAL_STATE)
        } else {
          const giftcard = await applyGiftCardCode(data?.discount)

          if (!(giftcard?.checkoutUserErrors?.length > 0)) {
            setApplied(true)
            setData(INITIAL_STATE)
          } else {
            setErrors(giftcard?.checkoutUserErrors)
            field?.current?.select()
          }
        }

        setLoading(false)
      },
      [applyDiscountCode, applyGiftCardCode, data, field, setApplied, setData, setLoading]
    )

    const handleChange = useCallback(
      ({ target: { type, name, value, checked } }) => {
        setApplied(false)
        setErrors([])
        setData((prevState: any) => ({
          ...prevState,
          [name]: type === "checkbox" ? checked : value,
        }))
      },
      [setApplied, setData]
    )

    useEffect(() => {
      if (checkout?.lineItems?.length > 0) {
        const items = checkout.lineItems.filter(
          item => typeof item.product?.productType !== "string" || item.product?.productType?.toLowerCase() !== "gift card"
        )
        if (items.length < 1) {
          setActiveBox(false)
        } else {
          setActiveBox(true)
        }
      }
    }, [checkout, count])

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          activeBox={activeBox}
          applied={applied}
          data={data}
          errors={errors}
          field={field}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          loading={loading}
          locales={page}
        />
      ),
      [activeBox, applied, data, errors, field, handleChange, handleSubmit, loading, page]
    )
  })
