import React from "react"
import { graphql } from "gatsby"

import { Cart as Page } from "../components/Cart/Cart"

export const query = graphql`
  query {
    page: sanityPageCart {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      breadcrumb: _rawBreadcrumb(resolveReferences: { maxDepth: 6 })
      link: _rawLink(resolveReferences: { maxDepth: 6 })
      recommendations
      recommendationsArrows
      recommendationsAutoplay
      recommendationsAutoplaySeconds
      additionalCheckout
      additionalDiscount
      additionalDiscountAdd
      additionalDiscountApply
      additionalDiscountApplied
      additionalEmpty
      additionalGiftcard
      additionalPickMix
      additionalPickMixEditButton
      additionalPickGift
      additionalPickGiftEditButton
      additionalCustomerNotePlaceholder
      additionalCustomerNoteSubmit
      additionalCustomerNoteError
      additionalRelated
      additionalShipping
      additionalShippingCalculated
      additionalShippingFree
      additionalSubtotal
      additionalTotal
    }
    global: sanityTemplateGlobal {
      additionalNext
      additionalPrevious
    }
  }
`

const Component = ({ data, ...props }): JSX.Element => <Page {...props} {...data} />

export default Component
