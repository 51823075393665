import React, { memo, useCallback, useMemo, useState } from "react"

import * as Components from "../../types/components"
import { UseRoutes } from "../../types/hooks"
import { useCheckout } from "../../hooks/useCheckout"
import { useRoutes } from "../../hooks/useRoutes"

export const withCart = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = "Cart", global, page }: ExternalProps) => {
    const { count, empty } = useCheckout()
    const { linkResolver } = useRoutes()

    const [bounds, setBounds] = useState<Bounds>({})
    const [canSaveNotes, setCanSaveNotes] = useState<boolean>(false)
    const [showNotesError, setNotesError] = useState<boolean>(false)

    const breadcrumb = useMemo(() => linkResolver(page?.breadcrumb), [linkResolver, page?.breadcrumb])
    const link = useMemo(() => linkResolver(page?.link), [linkResolver, page?.link])

    const handleResize = useCallback((contentRect: ContentRect) => setBounds(contentRect.bounds), [setBounds])

    const handleUpdateNoteStatus = useCallback((status: boolean) => setCanSaveNotes(status), [])

    const handleNotesError = useCallback((status: boolean) => setNotesError(status), [])

    const props = {
      bounds,
      breadcrumb,
      count,
      empty,
      global,
      handleResize,
      link,
      page,
      canSaveNotes,
      handleUpdateNoteStatus,
      showNotesError,
      handleNotesError,
    }

    Component.displayName = name
    return <Component {...(props as P)} />
  })

type Bounds = {
  top?: number
  right?: number
  bottom?: number
  left?: number
  width?: number
  height?: number
}
type ContentRect = {
  bounds: Bounds
  entry: DOMRectReadOnly
}

// TODO: Replace "any" with appropriate types
type ExternalProps = Components.ComponentProps & {
  page: any
  global: any
  showNotesError: boolean
  handleNotesError: (status: boolean) => void
}

type InjectedProps = ExternalProps & {
  bounds: Bounds
  breadcrumb: UseRoutes.Link
  canSaveNotes: boolean
  count: number
  empty: boolean
  link: UseRoutes.Link
  handleResize: (contentRect: ContentRect) => void
  handleUpdateNoteStatus: (status: boolean) => void
}
